<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g
            transform="translate(0 -1)"
            fill="none"
        >
            <circle
                fill="#CAC5C0"
                cx="50"
                cy="50.998"
                r="50"
            />
            <path
                d="M35.63 20.215v49.18c0 5.875 2.428 8.816 7.277 8.816h1.278v3.445h-26.185v-3.445h1.533c4.938 0 7.406-2.941 7.406-8.816v-32.32c0-5.955-2.043-8.939-6.128-8.939h-2.682v-3.322h1.019c5.109 0 9.709-1.531 13.795-4.598l2.687-.001zm50.965 46.367l-4.471 15.203h-37.429v-3.32c1.793-2.043 5.749-6.133 11.882-12.266 3.659-3.66 7.324-7.578 10.988-11.748 1.788-2.049 3.191-3.748 4.215-5.115 2.126-3.232 3.191-6.465 3.191-9.703 0-3.238-.981-6.092-2.938-8.561-1.959-2.471-4.559-3.705-7.791-3.705-6.731 0-12.012 4.598-15.843 13.799l-3.321-1.279c4.601-12.775 11.752-19.164 21.461-19.164 4.767 0 8.857 1.621 12.262 4.855 3.404 3.238 5.109 7.281 5.109 12.137 0 6.215-3.534 12.262-10.598 18.141l-7.282 5.873c-4.688 3.92-8.561 7.625-11.628 11.111-.254.262-.681.729-1.273 1.41h19.544c2.979 0 5.192-.557 6.643-1.664 1.445-1.102 2.766-3.107 3.956-6.004h3.323z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
