<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g
            transform="translate(0 -1)"
            fill="none"
        >
            <circle
                fill="#CAC5C0"
                cx="50"
                cy="50.998"
                r="50"
            />
            <path
                d="M37.162 19.574v50.205c0 5.962 2.51 8.939 7.537 8.939h1.277v3.451h-26.824v-3.451h1.787c5.027 0 7.537-2.978 7.537-8.939v-32.959c0-6.045-2.131-9.07-6.389-9.07h-2.682v-3.446h1.023c5.193 0 9.918-1.575 14.18-4.729l2.554-.001zm40.75 35.771c-4.684 4.429-9.365 6.643-14.049 6.643-5.109 0-9.242-2.089-12.393-6.263-2.812-3.659-4.216-8.13-4.216-13.41 0-5.708 1.658-10.817 4.979-15.329 3.746-4.854 8.39-7.281 13.925-7.281 6.728 0 12.049 2.895 15.967 8.686 3.66 5.197 5.494 11.284 5.494 18.265 0 10.053-3.3 18.504-9.899 25.359-6.602 6.855-14.881 10.327-24.846 10.41v-3.321c3.066-.171 5.494-.515 7.281-1.023 10.138-3.068 16.052-10.646 17.757-22.736zm.641-6.264c.082-1.356.129-2.635.129-3.83 0-4.938-.852-9.45-2.557-13.54-2.299-5.364-5.535-8.047-9.711-8.047-3.658 0-6.346 1.83-8.045 5.494-1.451 2.979-2.174 6.472-2.174 10.474 0 4.34.682 8.093 2.043 11.237 1.789 4.174 4.602 6.264 8.432 6.264 4.6 0 8.56-2.682 11.883-8.052z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
