<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g
            transform="translate(0 -1)"
            fill="none"
        >
            <circle
                fill="#CAC5C0"
                cx="50"
                cy="50.998"
                r="50"
            />
            <path
                d="M35.975 20.214v49.436c0 5.879 2.428 8.815 7.281 8.815h1.279v3.321h-26.316v-3.321h1.664c5.021 0 7.537-2.937 7.537-8.815v-32.444c0-5.962-2.133-8.939-6.389-8.939h-2.557v-3.322h1.023c5.109 0 9.709-1.574 13.795-4.729h2.683v-.002zm22.609 30.401l-1.277-1.019c-4.002-2.724-6.643-4.896-7.916-6.513-1.533-2.131-2.303-4.6-2.303-7.412 0-4.683 2-8.514 6.002-11.497 3.576-2.807 7.84-4.216 12.777-4.216 4.6 0 8.686 1.195 12.262 3.576 3.918 2.729 5.879 6.347 5.879 10.858 0 5.452-3.748 9.839-11.244 13.16 8.604 4.174 12.902 10.006 12.902 17.501 0 5.364-2.086 9.621-6.26 12.771-3.574 2.813-8.133 4.215-13.67 4.215-5.279 0-9.791-1.32-13.539-3.961-4.34-2.978-6.514-6.938-6.514-11.876 0-5.026 2.34-9.112 7.027-12.268 1.192-.844 3.151-1.952 5.874-3.319zm3.576 1.409c-6.215 2.895-9.324 7.406-9.324 13.54 0 3.919 1.279 7.09 3.83 9.517 2.559 2.428 5.75 3.639 9.586 3.639 3.576 0 6.596-.956 9.07-2.874 2.469-1.913 3.701-4.574 3.701-7.984 0-5.192-3.322-9.366-9.965-12.516l-6.898-3.322zm7.027-6.133c5.537-2.469 8.301-6.128 8.301-10.982 0-3.321-1.168-6.004-3.508-8.052-2.344-2.043-5.219-3.062-8.623-3.062-2.813 0-5.322.806-7.537 2.422-2.219 1.622-3.32 3.837-3.32 6.644 0 3.409 1.574 6.133 4.725 8.176.68.426 3.492 1.788 8.43 4.091l1.532.763z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
