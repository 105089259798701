<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g
            transform="translate(0 -1)"
            fill="none"
        >
            <circle
                fill="#CAC5C0"
                cx="50"
                cy="50.998"
                r="50"
            />
            <path
                d="M63.402 56.524c-5.922 5.567-11.898 8.353-17.91 8.353-6.555 0-11.809-2.604-15.758-7.814-3.684-4.758-5.523-10.507-5.523-17.241 0-7.272 2.152-13.777 6.461-19.526 4.672-6.199 10.555-9.296 17.648-9.296 8.622 0 15.489 3.728 20.605 11.181 4.582 6.554 6.867 14.319 6.867 23.302 0 12.746-4.224 23.498-12.655 32.257-8.441 8.752-18.993 13.174-31.653 13.261v-4.175c3.859-.176 6.953-.626 9.297-1.344 12.922-3.951 20.469-13.601 22.621-28.958zm.813-8.082c.086-1.706.129-3.322.129-4.846 0-6.285-1.074-12.034-3.23-17.24-2.875-6.916-7-10.374-12.386-10.374-4.676 0-8.125 2.385-10.375 7.141-1.797 3.683-2.691 8.126-2.691 13.334 0 5.566.852 10.324 2.559 14.275 2.332 5.387 5.926 8.083 10.777 8.083 5.92 0 10.99-3.458 15.217-10.373z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
