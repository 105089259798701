<template>
    <svg width="154" height="178" viewBox="0 0 154 178" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 45L77 1L153 45V133L77 177L1 133V45Z" fill="#672900"/>
        <path d="M115.3 51.2L130.9 61.1C130.9 61.1 130.5 66.7 129.9 69.5C128.9 74.3 127.5 79.1 125.7 83.6C124.1 87.4 120 94.6 120 94.6L110.3 83.2L113.7 78.4C114.8 76.6 116.2 74.9 117 72.9C118 70.4 118.9 64.9 118.9 64.9L112.2 61.6L115.3 51.2ZM104.3 85.4L126.5 111.6C126.5 111.6 128.5 113.4 128.8 114.6C129.3 115.8 129.4 117.1 129 118.3C128.6 119.3 127 120.8 127 120.8L107 135.7L103.2 136.1L101.7 134.8L101.4 132.8L107.6 124.6L113.3 117.6L112.6 114.9L97.5 95.9L104.3 85.4Z" fill="#FDD868"/>
        <path d="M77 26.7L41 48.4C41 48.4 47.5 74 53.2 85.7C58.8125 96.4579 65.6586 106.526 73.6 115.7C76.8 119.8 84.5 126.9 84.5 126.9L76.8 135.2L67 126.3L73.6 121L65.2 112.5L60.7 117.7L57.6 115.9L56.5 113.5L55.2 109.4L45 117C45 117 51.2 127 54.7 131.8C57.7 135.8 60.6 140 64.1 143.5C68.1 147.4 77.1 153.6 77.1 153.6C77.1 153.6 86.1 145.8 90.1 141.3C93.3599 137.547 96.3984 133.607 99.2 129.5C102.5 124.8 108.4 115.2 108.4 115.2L102.4 107.9L94.6 117.3C94.6 117.3 80.6 106.5 75.2 99.7C69.583 92.4157 65.099 84.3243 61.9 75.7C59.8352 68.8887 58.2645 61.9373 57.2 54.9L77 41.5L95.4 54.9C95.4 54.9 93 66.9 91 72.6C89.5158 77.008 87.6069 81.2613 85.3 85.3C83.2438 88.4919 81.0069 91.5635 78.6 94.5L86 105.7C86 105.7 93.8 95.2 97 89.4C100.7 82.5 104 75.4 106.2 67.8C108.2 61.4 110 48 110 48L77 26.7Z" fill="#FDD868"/>
        <path d="M64.2 69.3L76.9 56.3L89 69.6L83 77.6L77 70.9L70.7 77.7L64.2 69.3ZM37.5 51.2L22 61C22 61 22.3 66.7 23 69.5C23.9085 74.2888 25.2817 78.9776 27.1 83.5C28.7 87.4 32.9 94.5 32.9 94.5L42.6 83L39.2 78.3C38 76.5 36.7 74.8 35.9 72.8C34.9 70.2 33.9 64.8 33.9 64.8L40.7 61.4L37.5 51.1V51.2ZM48.5 85.3L26.3 111.6C26.3 111.6 24.4 113.4 24 114.6C23.5785 115.758 23.5434 117.021 23.9 118.2C24.2 119.2 25.9 120.7 25.9 120.7L45.7 135.6L49.5 136L51.1 134.8L51.3 132.8L45.2 124.5L39.4 117.5L40.1 114.8L55.3 95.8L48.6 85.3H48.5Z" fill="#FDD868"/>
        <path d="M1 45L77 1L153 45V133L77 177L1 133V45Z" stroke="#FDD868"/>
        <path d="M115.3 51.2L130.9 61.1C130.9 61.1 130.5 66.7 129.9 69.5C128.9 74.3 127.5 79.1 125.7 83.6C124.1 87.4 120 94.6 120 94.6L110.3 83.2L113.7 78.4C114.8 76.6 116.2 74.9 117 72.9C118 70.4 118.9 64.9 118.9 64.9L112.2 61.6L115.3 51.2ZM104.3 85.4L126.5 111.6C126.5 111.6 128.5 113.4 128.8 114.6C129.3 115.8 129.4 117.1 129 118.3C128.6 119.3 127 120.8 127 120.8L107 135.7L103.2 136.1L101.7 134.8L101.4 132.8L107.6 124.6L113.3 117.6L112.6 114.9L97.5 95.9L104.3 85.4Z" stroke="#FDD868"/>
        <path d="M77 26.7L41 48.4C41 48.4 47.5 74 53.2 85.7C58.8125 96.4579 65.6586 106.526 73.6 115.7C76.8 119.8 84.5 126.9 84.5 126.9L76.8 135.2L67 126.3L73.6 121L65.2 112.5L60.7 117.7L57.6 115.9L56.5 113.5L55.2 109.4L45 117C45 117 51.2 127 54.7 131.8C57.7 135.8 60.6 140 64.1 143.5C68.1 147.4 77.1 153.6 77.1 153.6C77.1 153.6 86.1 145.8 90.1 141.3C93.3599 137.547 96.3984 133.607 99.2 129.5C102.5 124.8 108.4 115.2 108.4 115.2L102.4 107.9L94.6 117.3C94.6 117.3 80.6 106.5 75.2 99.7C69.583 92.4157 65.099 84.3243 61.9 75.7C59.8352 68.8887 58.2645 61.9373 57.2 54.9L77 41.5L95.4 54.9C95.4 54.9 93 66.9 91 72.6C89.5158 77.008 87.6069 81.2613 85.3 85.3C83.2438 88.4919 81.0069 91.5635 78.6 94.5L86 105.7C86 105.7 93.8 95.2 97 89.4C100.7 82.5 104 75.4 106.2 67.8C108.2 61.4 110 48 110 48L77 26.7Z" stroke="#FDD868"/>
        <path d="M64.2 69.3L76.9 56.3L89 69.6L83 77.6L77 70.9L70.7 77.7L64.2 69.3ZM37.5 51.2L22 61C22 61 22.3 66.7 23 69.5C23.9085 74.2888 25.2817 78.9776 27.1 83.5C28.7 87.4 32.9 94.5 32.9 94.5L42.6 83L39.2 78.3C38 76.5 36.7 74.8 35.9 72.8C34.9 70.2 33.9 64.8 33.9 64.8L40.7 61.4L37.5 51.1V51.2ZM48.5 85.3L26.3 111.6C26.3 111.6 24.4 113.4 24 114.6C23.5785 115.758 23.5434 117.021 23.9 118.2C24.2 119.2 25.9 120.7 25.9 120.7L45.7 135.6L49.5 136L51.1 134.8L51.3 132.8L45.2 124.5L39.4 117.5L40.1 114.8L55.3 95.8L48.6 85.3H48.5Z" stroke="#FDD868"/>
    </svg>
</template>
