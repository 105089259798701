<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g fill="none">
            <circle
                fill="#C9C4BE"
                cx="50.001"
                cy="50"
                r="50"
            />
            <path
                id="Shape"
                fill="#0D0F0F"
                d="M37.843 89.157l2.355-4.08 9.803 13.804 9.802-13.804 2.355 4.08 4.338.051 8.505 4.095.707-9.412 2.125-3.786-2.354-4.078 16.855-1.588-7.055-15.389h4.711l2.213-3.732 7.797-5.318-7.797-5.319-2.213-3.731h-4.711l7.054-15.391-16.854-1.586 2.354-4.08-2.126-3.782-.707-9.412-8.504 4.093-4.338.051-2.355 4.079-9.803-13.804-9.802 13.804-2.355-4.079-4.338-.051-8.505-4.093-.707 9.412-2.125 3.782 2.355 4.08-16.856 1.587 7.055 15.39h-4.711l-2.213 3.732-7.798 5.318 7.797 5.318 2.214 3.732h4.711l-7.054 15.391 16.855 1.586-2.355 4.078 2.125 3.784.708 9.414 8.504-4.094z"
            />
            <path
                d="M34.895 80.211l15.105-24.336 15.105 24.336v4.048l3.504 2.023 3.507-2.024v-4.046l-3.507-2.024-13.521-25.25 28.629-.915 3.506 2.025 3.504-2.023v-4.049l-3.504-2.023-3.506 2.023-28.629-.913 13.523-25.25 3.506-2.024v-4.046l-3.506-2.024-3.504 2.023-.002 4.047-15.104 24.336-15.105-24.336-.001-4.048-3.503-2.023-3.507 2.025v4.046l3.506 2.024 13.522 25.25-28.628.913-3.507-2.023-3.503 2.022v4.048l3.503 2.024 3.507-2.024 28.628.915-13.523 25.25-3.505 2.023-.001 4.047 3.506 2.023 3.505-2.023v-4.047zm22.156-57.818l-7.05 12.288-7.05-12.288 7.05-12.289 7.05 12.289zm-27.434 7.697l7.117 12.25-14.167-.039-7.117-12.249 14.167.038zm-7.051 27.607l14.167-.038-7.117 12.249-14.167.039 7.117-12.25zm20.383 19.91l7.051-12.289 7.05 12.289-7.05 12.289-7.051-12.289zm27.435-7.697l-7.116-12.25 14.166.039 7.117 12.248-14.167-.037zm7.052-27.607l-14.168.038 7.117-12.249 14.166-.039-7.115 12.25z"
                fill="#fff"
            />
        </g>
    </svg>
</template>
