<script setup lang="ts">
import {AntlerClasses, installAntlerComponent} from "@/Utils/component";

type Props = {
    cost: number;
    inkable?: number;
};

const { cost, inkable = 0 } = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "inline-flex justify-center items-center p-1 bg-black text-white h-6 min-w-6 basis-6 shrink-0 border-2 border-yellow-500 text-sm",
};

const { aClass } = installAntlerComponent("lorcana-cost", {}, classes);
</script>

<template>
    <div
        :class="aClass({
            'rounded-full border-4 h-7 min-w-7 basis-7' : inkable,
        })"
    >
        {{ cost }}
    </div>
</template>
