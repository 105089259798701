<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 100"
    >
        <g fill="none">
            <path
                id="Shape"
                d="M0 0v100c27.613 0 50-22.387 50-50 0-27.615-22.387-50-50-50z"
                fill="#E49977"
            />
            <path
                d="M34.687 68.145c-1.445.68-4.5 1.656-9.168 2.928 1.273-1.961 3.648-4.73 7.129-8.313 3.477-3.582 5.859-6.523 7.129-8.826 1.949-3.408 2.93-7.203 2.93-11.381 0-7.93-3.484-16.332-10.441-25.199 1.102 2.752 1.91 4.818 2.422 6.193 1.355 3.527 2.035 6.453 2.035 8.777 0 1.805-.469 3.098-1.402 3.871-5.133-18.535-16.879-28.299-35.215-29.309v4.578c3.016.992 4.539 2.23 4.539 3.725 0 .668-1.531 1.383-4.539 2.143v3.832l.086.01c1.949 0 4.113-.639 6.492-1.91 2.371-1.273 4.152-1.91 5.344-1.91 2.543 0 4.625 1.316 6.238 3.947 1.441 2.207 2.164 4.668 2.164 7.383 0 2.121-.512 3.182-1.527 3.182-.766 0-1.867-1.061-3.313-3.182-1.02-1.611-1.781-2.801-2.289-3.564-.172.598-.254 1.189-.254 1.783 0 3.564 1.633 6.871 4.898 9.928 3.265 3.059 6.684 4.668 10.25 4.84-.172 2.291-.766 4.668-1.781 7.127-1.191 3.227-2.551 5.137-4.074 5.729-1.02-1.018-1.527-2.459-1.527-4.326 0-.848.273-2.166.824-3.947.555-1.781.828-3.057.828-3.818-.848.34-1.805 1.252-2.863 2.738-1.063 1.484-1.676 2.734-1.844 3.754-.594 3.563-.766 6.619-.512 9.164.254 2.209-.68 4.668-2.801 7.385-2.121 2.715-4.285 4.072-6.492 4.072-1.445 0-2.758-.297-3.945-.891-1.527-.764-2.293-1.783-2.293-3.057 0-2.035 1.824-4.113 5.473-6.236 5.094-3.055 8.066-9.037 8.914-17.949.254 0-1.953 1.295-6.621 3.883-3.574 1.984-6.695 3.199-9.375 3.664v43.369c10.566-.686 18.539-2.59 23.887-5.725-1.273 0-4.414-.064-9.422-.191-5.004-.127-6.449-.191-4.324-.191-.426 0-.938-.273-1.527-.826-.598-.553-.891-1.039-.891-1.463 0-1.357 1.781-2.422 5.344-3.186 2.375-.508 4.586-.762 6.621-.762 11.117 0 18.539-4.455 22.277-13.367.934-2.205 1.398-3.861 1.398-4.965-2.633 2.881-5.559 5.047-8.782 6.489zm-28.324-36.215c1.57-.975 2.355-2.186 2.355-3.629 0-2.969-1.402-4.455-4.203-4.455-1.188 0-2.27.594-3.246 1.781-.449.547-.832.953-1.164 1.25v6.246c.523.17 1.059.271 1.609.271 1.528.001 3.079-.488 4.649-1.464z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
