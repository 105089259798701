<script setup lang="ts">
import {
    AntlerClasses,
    installAntlerComponent,
} from "../../../Utils/component";
import MtgIconWhite from "./MtgIconWhite.vue";
import MtgIconTap from "./MtgIconTap.vue";
import MtgIcon1 from "./MtgIcon1.vue";
import MtgIcon2 from "./MtgIcon2.vue";
import MtgIcon3 from "./MtgIcon3.vue";
import MtgIconRed from "@/Games/Mtg/icons/MtgIconRed.vue";
import MtgIconBlue from "@/Games/Mtg/icons/MtgIconBlue.vue";
import MtgIconBlack from "@/Games/Mtg/icons/MtgIconBlack.vue";
import MtgIconGreen from "@/Games/Mtg/icons/MtgIconGreen.vue";
import MtgIcon4 from "@/Games/Mtg/icons/MtgIcon4.vue";
import MtgIconAcornCounter from "@/Games/Mtg/icons/MtgIconAcornCounter.vue";
import MtgIconUntap from "@/Games/Mtg/icons/MtgIconUntap.vue";
import MtgIconEnergyCounter from "@/Games/Mtg/icons/MtgIconEnergyCounter.vue";
import MtgIconTicketCounter from "@/Games/Mtg/icons/MtgIconTicketCounter.vue";
import MtgIconX from "@/Games/Mtg/icons/MtgIconX.vue";
import MtgIconY from "@/Games/Mtg/icons/MtgIconY.vue";
import MtgIconZ from "@/Games/Mtg/icons/MtgIconZ.vue";
import MtgIconHalf from "@/Games/Mtg/icons/MtgIconHalf.vue";
import MtgIcon5 from "@/Games/Mtg/icons/MtgIcon5.vue";
import MtgIcon6 from "@/Games/Mtg/icons/MtgIcon6.vue";
import MtgIcon7 from "@/Games/Mtg/icons/MtgIcon7.vue";
import MtgIcon8 from "@/Games/Mtg/icons/MtgIcon8.vue";
import MtgIcon9 from "@/Games/Mtg/icons/MtgIcon9.vue";
import MtgIcon10 from "@/Games/Mtg/icons/MtgIcon10.vue";
import MtgIcon11 from "@/Games/Mtg/icons/MtgIcon11.vue";
import MtgIcon12 from "@/Games/Mtg/icons/MtgIcon12.vue";
import MtgIcon13 from "@/Games/Mtg/icons/MtgIcon13.vue";
import MtgIcon14 from "@/Games/Mtg/icons/MtgIcon14.vue";
import MtgIcon15 from "@/Games/Mtg/icons/MtgIcon15.vue";
import MtgIcon16 from "@/Games/Mtg/icons/MtgIcon16.vue";
import MtgIcon17 from "@/Games/Mtg/icons/MtgIcon17.vue";
import MtgIcon18 from "@/Games/Mtg/icons/MtgIcon18.vue";
import MtgIcon19 from "@/Games/Mtg/icons/MtgIcon19.vue";
import MtgIcon20 from "@/Games/Mtg/icons/MtgIcon20.vue";
import MtgIcon100 from "@/Games/Mtg/icons/MtgIcon100.vue";
import MtgIcon1000000 from "@/Games/Mtg/icons/MtgIcon1000000.vue";
import MtgIconWu from "@/Games/Mtg/icons/MtgIconWu.vue";
import MtgIconWb from "@/Games/Mtg/icons/MtgIconWb.vue";
import MtgIconBr from "@/Games/Mtg/icons/MtgIconBr.vue";
import MtgIconBg from "@/Games/Mtg/icons/MtgIconBg.vue";
import MtgIconUb from "@/Games/Mtg/icons/MtgIconUb.vue";
import MtgIconUr from "@/Games/Mtg/icons/MtgIconUr.vue";
import MtgIconRg from "@/Games/Mtg/icons/MtgIconRg.vue";
import MtgIconRw from "@/Games/Mtg/icons/MtgIconRw.vue";
import MtgIconGw from "@/Games/Mtg/icons/MtgIconGw.vue";
import MtgIconGu from "@/Games/Mtg/icons/MtgIconGu.vue";
import MtgIconBgp from "@/Games/Mtg/icons/MtgIconBgp.vue";
import MtgIconBrp from "@/Games/Mtg/icons/MtgIconBrp.vue";
import MtgIconGup from "@/Games/Mtg/icons/MtgIconGup.vue";
import MtgIconGwp from "@/Games/Mtg/icons/MtgIconGwp.vue";
import MtgIconRgp from "@/Games/Mtg/icons/MtgIconRgp.vue";
import MtgIconRwp from "@/Games/Mtg/icons/MtgIconRwp.vue";
import MtgIconUbp from "@/Games/Mtg/icons/MtgIconUbp.vue";
import MtgIconUrp from "@/Games/Mtg/icons/MtgIconUrp.vue";
import MtgIconWbp from "@/Games/Mtg/icons/MtgIconWbp.vue";
import MtgIconWup from "@/Games/Mtg/icons/MtgIconWup.vue";
import MtgIcon2W from "@/Games/Mtg/icons/MtgIcon2W.vue";
import MtgIcon2U from "@/Games/Mtg/icons/MtgIcon2U.vue";
import MtgIcon2B from "@/Games/Mtg/icons/MtgIcon2B.vue";
import MtgIcon2R from "@/Games/Mtg/icons/MtgIcon2R.vue";
import MtgIcon2G from "@/Games/Mtg/icons/MtgIcon2G.vue";
import MtgIconP from "@/Games/Mtg/icons/MtgIconP.vue";
import MtgIconWp from "@/Games/Mtg/icons/MtgIconWp.vue";
import MtgIconUp from "@/Games/Mtg/icons/MtgIconUp.vue";
import MtgIconBp from "@/Games/Mtg/icons/MtgIconBp.vue";
import MtgIconRp from "@/Games/Mtg/icons/MtgIconRp.vue";
import MtgIconGp from "@/Games/Mtg/icons/MtgIconGp.vue";
import MtgIconHw from "@/Games/Mtg/icons/MtgIconHw.vue";
import MtgIconHr from "@/Games/Mtg/icons/MtgIconHr.vue";
import MtgIconColorless from "@/Games/Mtg/icons/MtgIconColorless.vue";
import MtgIconS from "@/Games/Mtg/icons/MtgIconS.vue";
import MtgIcon0 from "@/Games/Mtg/icons/MtgIcon0.vue";

export type IconName =
    | "A"
    | "S"
    | "U"
    | "UR"
    | "UB"
    | "UBP"
    | "URP"
    | "2U"
    | "UP"
    | "W"
    | "WU"
    | "WB"
    | "WBP"
    | "2W"
    | "WP"
    | "HW"
    | "G"
    | "GW"
    | "GUP"
    | "GWP"
    | "2G"
    | "GP"
    | "T"
    | "TK"
    | "P"
    | "X"
    | "Y"
    | "Z"
    | "Q"
    | "E"
    | "B"
    | "BR"
    | "BG"
    | "BGP"
    | "BRP"
    | "2B"
    | "BP"
    | "R"
    | "RG"
    | "RW"
    | "RGP"
    | "2R"
    | "RP"
    | "HR"
    | "½"
    | "1"
    | "2"
    | "3"
    | "4"
    | "5"
    | "6"
    | "7"
    | "8"
    | "9"
    | "10"
    | "11"
    | "12"
    | "13"
    | "14"
    | "15"
    | "16"
    | "17"
    | "18"
    | "19"
    | "20"
    | "100"
    | "1000000";

export type IconSize = "";
export type IconColor = "current" | "primary" | "none";

type Props = {
    name: IconName;
    size?: IconSize;
    color?: IconColor;
};

const { size = "regular", color = "current", name } = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "inline-flex items-center justify-center shrink-0 w-5 h-5 basis-5 rounded-full mx-0.5",
    variants: {
        size: {
            "": "",
        },
        color: {
            current: "text-current fill-current",
            primary: "text-theme fill-theme",
            none: "fill-none",
        },
    },
};

const { aClass } = installAntlerComponent("mtg-icon", { size, color }, classes);
</script>

<template>
    <MtgIconWhite
        v-if="name === 'W'"
        :class="aClass()"
    />
    <MtgIconWu
        v-else-if="name === 'WU'"
        :class="aClass()"
    />
    <MtgIconWb
        v-else-if="name === 'WB'"
        :class="aClass()"
    />
    <MtgIconWbp
        v-else-if="name === 'WBP'"
        :class="aClass()"
    />
    <MtgIconWup
        v-else-if="name === 'WUP'"
        :class="aClass()"
    />
    <MtgIcon2W
        v-else-if="name === '2W'"
        :class="aClass()"
    />
    <MtgIconWp
        v-else-if="name === 'WP'"
        :class="aClass()"
    />
    <MtgIconHw
        v-else-if="name === 'HW'"
        :class="aClass()"
    />

    <MtgIconRed
        v-else-if="name === 'R'"
        :class="aClass()"
    />
    <MtgIconRg
        v-else-if="name === 'RG'"
        :class="aClass()"
    />
    <MtgIconRgp
        v-else-if="name === 'RGP'"
        :class="aClass()"
    />
    <MtgIconRw
        v-else-if="name === 'RW'"
        :class="aClass()"
    />
    <MtgIconRwp
        v-else-if="name === 'RWP'"
        :class="aClass()"
    />
    <MtgIcon2R
        v-else-if="name === '2R'"
        :class="aClass()"
    />
    <MtgIconRp
        v-else-if="name === 'RP'"
        :class="aClass()"
    />
    <MtgIconHr
        v-else-if="name === 'HR'"
        :class="aClass()"
    />

    <MtgIconBlue
        v-else-if="name === 'U'"
        :class="aClass()"
    />
    <MtgIconUb
        v-else-if="name === 'UB'"
        :class="aClass()"
    />
    <MtgIconUr
        v-else-if="name === 'UR'"
        :class="aClass()"
    />
    <MtgIconUbp
        v-else-if="name === 'UBP'"
        :class="aClass()"
    />
    <MtgIconUrp
        v-else-if="name === 'URP'"
        :class="aClass()"
    />
    <MtgIcon2U
        v-else-if="name === '2U'"
        :class="aClass()"
    />
    <MtgIconUp
        v-else-if="name === 'UP'"
        :class="aClass()"
    />
    <MtgIconBlack
        v-else-if="name === 'B'"
        :class="aClass()"
    />
    <MtgIconBr
        v-else-if="name === 'BR'"
        :class="aClass()"
    />
    <MtgIconBg
        v-else-if="name === 'BG'"
        :class="aClass()"
    />
    <MtgIconBgp
        v-else-if="name === 'BGP'"
        :class="aClass()"
    />
    <MtgIconBrp
        v-else-if="name === 'BRP'"
        :class="aClass()"
    />
    <MtgIcon2B
        v-else-if="name === '2B'"
        :class="aClass()"
    />
    <MtgIconBp
        v-else-if="name === 'BP'"
        :class="aClass()"
    />

    <MtgIconGreen
        v-else-if="name === 'G'"
        :class="aClass()"
    />
    <MtgIconGw
        v-else-if="name === 'GW'"
        :class="aClass()"
    />
    <MtgIconGu
        v-else-if="name === 'GU'"
        :class="aClass()"
    />
    <MtgIconGup
        v-else-if="name === 'GUP'"
        :class="aClass()"
    />
    <MtgIconGwp
        v-else-if="name === 'GWP'"
        :class="aClass()"
    />
    <MtgIcon2G
        v-else-if="name === '2G'"
        :class="aClass()"
    />
    <MtgIconGp
        v-else-if="name === 'GP'"
        :class="aClass()"
    />

    <MtgIconTap
        v-else-if="name === 'T'"
        :class="aClass()"
    />
    <MtgIconUntap
        v-else-if="name === 'Q'"
        :class="aClass()"
    />
    <MtgIconColorless
        v-else-if="name === 'C'"
        :class="aClass()"
    />
    <MtgIconS
        v-else-if="name === 'S'"
        :class="aClass()"
    />
    <MtgIconX
        v-else-if="name === 'X'"
        :class="aClass()"
    />
    <MtgIconP
        v-else-if="name === 'P'"
        :class="aClass()"
    />
    <MtgIconY
        v-else-if="name === 'Y'"
        :class="aClass()"
    />
    <MtgIconZ
        v-else-if="name === 'Z'"
        :class="aClass()"
    />
    <MtgIconAcornCounter
        v-else-if="name === 'A'"
        :class="aClass()"
    />
    <MtgIconEnergyCounter
        v-else-if="name === 'E'"
        :class="aClass()"
    />
    <MtgIconTicketCounter
        v-else-if="name === 'TK'"
        :class="aClass()"
    />

    <MtgIconHalf
        v-else-if="name === '½'"
        :class="aClass()"
    />
    <MtgIcon0
        v-else-if="name === '0'"
        :class="aClass()"
    />
    <MtgIcon1
        v-else-if="name === '1'"
        :class="aClass()"
    />
    <MtgIcon2
        v-else-if="name === '2'"
        :class="aClass()"
    />
    <MtgIcon3
        v-else-if="name === '3'"
        :class="aClass()"
    />
    <MtgIcon4
        v-else-if="name === '4'"
        :class="aClass()"
    />
    <MtgIcon5
        v-else-if="name === '5'"
        :class="aClass()"
    />
    <MtgIcon6
        v-else-if="name === '6'"
        :class="aClass()"
    />
    <MtgIcon7
        v-else-if="name === '7'"
        :class="aClass()"
    />
    <MtgIcon8
        v-else-if="name === '8'"
        :class="aClass()"
    />
    <MtgIcon9
        v-else-if="name === '9'"
        :class="aClass()"
    />
    <MtgIcon10
        v-else-if="name === '10'"
        :class="aClass()"
    />
    <MtgIcon11
        v-else-if="name === '11'"
        :class="aClass()"
    />
    <MtgIcon12
        v-else-if="name === '12'"
        :class="aClass()"
    />
    <MtgIcon13
        v-else-if="name === '13'"
        :class="aClass()"
    />
    <MtgIcon14
        v-else-if="name === '14'"
        :class="aClass()"
    />
    <MtgIcon15
        v-else-if="name === '15'"
        :class="aClass()"
    />
    <MtgIcon16
        v-else-if="name === '16'"
        :class="aClass()"
    />
    <MtgIcon17
        v-else-if="name === '17'"
        :class="aClass()"
    />
    <MtgIcon18
        v-else-if="name === '18'"
        :class="aClass()"
    />
    <MtgIcon19
        v-else-if="name === '19'"
        :class="aClass()"
    />
    <MtgIcon20
        v-else-if="name === '20'"
        :class="aClass()"
    />
    <MtgIcon100
        v-else-if="name === '100'"
        :class="aClass()"
    />
    <MtgIcon1000000
        v-else-if="name === '1000000'"
        :class="aClass()"
    />
</template>
