<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g fill="none">
            <path
                id="Shape"
                d="M100 49.998c0 27.613-22.383 50.002-50 50.002-27.613 0-50-22.387-50-50.001 0-27.615 22.387-49.999 50-49.999 27.617 0 50 22.384 50 49.998z"
                fill="#C9C4BE"
            />
            <path
                d="M54.91 65.695c0 3.988.654 6.793 1.963 8.414 1.309 1.621 3.521 2.431 6.639 2.431l.748-.095v3.555h-28.796v-3.553h.934c3.117.063 5.33-.717 6.639-2.338 1.309-1.619 1.963-4.394 1.963-8.319v-12.53l-18.325-26.833-1.964-2.618c-.561-.748-1.091-1.339-1.589-1.776-.811-.686-1.668-1.169-2.571-1.45-.904-.281-2.073-.514-3.506-.701v-3.647h27.955v3.553c-2.805.25-4.708.64-5.703 1.168-.998.53-1.496 1.512-1.496 2.945 0 .624.124 1.248.373 1.87.249.624.592 1.278 1.029 1.963l14.212 20.569 10.378-15.24c.996-1.371 1.713-2.555 2.15-3.553.435-.996.715-1.931.841-2.804v-.749c0-1.931-.53-3.366-1.589-4.3-1.06-.936-2.899-1.527-5.516-1.776v-3.647h23.093v3.553c-.748.125-1.435.296-2.056.514-.624.219-1.216.515-1.777.889-.998.561-2.026 1.449-3.085 2.665-1.061 1.215-2.337 2.915-3.833 5.096l-17.11 24.309v12.436h-.001v-.001z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
