<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g fill="none">
            <path
                id="Shape"
                d="M100 49.998c0 27.615-22.387 50.002-50.002 50.002-27.613 0-49.998-22.387-49.998-50.002 0-27.613 22.385-49.998 49.998-49.998 27.615 0 50.002 22.385 50.002 49.998z"
                fill="#CAC5C0"
            />
            <path
                d="M34.67 18.129v50.84c0 6.045 2.516 9.07 7.537 9.07h1.277v3.445h-27.078v-3.445h1.787c5.109 0 7.666-3.025 7.666-9.07v-33.344c0-6.129-2.172-9.195-6.518-9.195h-2.682v-3.449h1.023c5.281 0 10.047-1.617 14.305-4.852h2.683zm17.885 1.273h23.121c2.984 0 4.771-.336 5.369-1.02h3.322l-1.918 8.688h-28.229l-2.682 12.516c3.998-1.617 7.578-2.428 10.729-2.428 6.133 0 11.305 2.049 15.52 6.135 4.215 4.084 6.32 9.193 6.32 15.328 0 7.152-2.723 12.898-8.17 17.246-5.115 4-11.291 6.004-18.525 6.004-4.258 0-8.43-.641-12.516-1.918l1.273-3.191c3.404.934 6.43 1.402 9.07 1.402 5.281 0 9.902-1.742 13.861-5.234 3.961-3.492 5.936-7.84 5.936-13.031 0-4.512-1.465-8.219-4.402-11.113-2.941-2.895-6.621-4.344-11.051-4.344-4.172 0-8.045 1.449-11.625 4.344l-2.168-.383 6.765-29.001z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
