<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g fill="none">
            <circle
                fill="#F8F6D8"
                cx="50"
                cy="50"
                r="50"
            />
            <path
                d="M77.307 49.928c.059-5.344-1.639-10.738-4.967-14.928-1.607-1.77-2.931-3.787-4.633-5.461-3.755-3.725-9.375-3.973-13.896-6.32-.264-2.357-1.125-4.715-.604-7.094.159-.791.393-1.592-.029-2.348-1.187-2.732-.166-5.699-.377-8.547-.158-1.488-.058-3.309-1.476-4.229-.33 3.098-1.469 5.98-2.392 8.918-.426 2.291-.426 4.676-1.413 6.836.627 2.189-.613 4.031-1.955 5.646-2.968 1.52-6.496 1.881-9.078 4.109-1.84 1.563-3.828 2.932-5.887 4.203-1.545 1.934-3.256 3.797-3.945 6.24-2.762 3.334-3.088 7.811-4.654 11.717-.029 5.799 1.146 12.043 4.85 16.691 2.65 2.188 4.502 5.131 7.149 7.318 2.541 1.518 5.252 2.764 7.875 4.127 1.709.4 3.475.596 5.176 1.061 1.379 7.109 2.322 14.389 5.092 21.133.918-2.768.807-5.736 1.6-8.533 1.119-4.135-1.176-8.439.117-12.5 1.938-1.732 5.17-.607 7.32-2.094 4.092-2.523 8.422-4.98 11.391-8.84 1.105-2.219 3.426-3.877 3.426-6.539.019-3.583 2.109-6.941 1.31-10.566zm-30.373 21.932c-2.965-.721-5.727-2.139-8.213-3.871-2.676-1.783-3.674-5.109-6.314-6.934-2.473-2.949-1.938-6.98-2.951-10.475.406-2.439.885-4.857 1.139-7.326 1.205-1.955 3.066-3.514 3.582-5.869 1.769-2.533 4.459-4.285 6.619-6.48 1.572-1.813 4.074-1.047 6.139-1.119-.204 2-.366 4.07.134 6.041.244 1.213.791 2.408.604 3.676-.414 2.699.598 5.408-.117 8.076-1.443 5.576.131 11.209.268 16.832-.206 2.488-.323 4.998-.89 7.449zm22.477-10.57c-1.963 1.398-3.637 3.111-5.35 4.785-2.979 2.061-5.924 4.24-9.469 5.27.319-2.529.631-5.131-.174-7.607-1.979-5.594.444-11.445.854-17.102-.361-3.363-.017-6.951-1.56-10.049-.201-2.326.806-4.574 1.486-6.764 2.342.936 4.547 2.197 6.496 3.82 2.354 1.766 5.961 2.908 6.488 6.199.268 2.16 2.633 3.467 2.564 5.678-.146 5.256.579 10.752-1.335 15.77z"
                fill="#111212"
            />
        </g>
    </svg>
</template>
