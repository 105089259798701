<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 102 101"
    >
        <g
            stroke="#fff"
            fill="none"
        >
            <path
                d="M20.796 1c31.629 5.815 45.711 4.744 58.731 0 0 0 4.376 42.061 21.428 62.559 0 0-30.335 8.504-50.112 37.308 0 0-19.364-27.014-49.842-37.789 9.542-13.121 18.357-39.075 19.796-62.078z"
                fill="#000"
            />
            <path
                d="M38.272 10.497c-.211-1.696 6.723 4.506 26.444-.587 0 0-14.239 11.917-15.834 31.303 0 0 15.408 3.458 29.732-1.089 0 0-21.436 27.626-26.954 52.361 0 0-6.005-13.6.205-36.533 0 0-7.38-5.067-33.694 1.59 0 0 22.678-26.323 20.101-47.044z"
                fill="#fff"
            />
        </g>
    </svg>
</template>
