<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 539 696"
    >
        <g
            fill="#000"
            fill-rule="nonzero"
        >
            <path
                d="M233.5 60 58 541l35 13c31.667-29.333 67.667-36.5 108-21.5 41.667 16 63.333 46.333 65 91l36.5 14.5 177-489.5L445 135c-36.667 35.667-77.5 44.167-122.5 25.5-38-16.667-56.833-45.667-56.5-87L233.5 60Z"
            />
            <path
                d="M314.304 46.096 206.274.541.867 567.782l106.355 40.364 6.871-7.55c22.93-25.194 45.17-32.613 69.069-24.253l.865.31.142.048c25.585 8.704 37.477 29.164 36.338 65.327l-.335 10.665 111.77 42.792 206.115-574.177-108.668-42.19-6.903 8.268-.752.895c-24.569 29.041-48.604 38.645-74.023 31.124l-.483-.146.3.109c-27.827-9.9-38.79-29.116-34.646-61.992l1.422-11.28Zm-90.579-5.638 58.435 24.641-.028.733c-1.306 39.605 17.193 67.794 54.213 81.393l1.423.513.3.092 1.16.351c35.177 10.41 68.51-1.127 98.465-32.76l.617-.657 61.633 23.928-185.885 517.822-63.577-24.341-.018-.54c-1.61-41.84-20.265-70.474-55.465-82.929l-1-.347-.979-.348c-33.179-11.596-64.998-2.846-93.635 24.696l-.281.272-59.97-22.76L223.725 40.458Z"
            />
        </g>
    </svg>
</template>
