<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g
            transform="translate(0 -1)"
            fill="none"
        >
            <circle
                fill="#CAC5C0"
                cx="50"
                cy="50.998"
                r="50"
            />
            <path
                d="M40.965 50.463l-1.746-1.211c-5.119-3.502-8.492-6.33-10.107-8.489-2.066-2.691-3.096-5.83-3.096-9.426 0-6.105 2.557-11.087 7.678-14.949 4.758-3.59 10.281-5.388 16.568-5.388 5.922 0 11.18 1.573 15.756 4.713 5.203 3.503 7.813 8.127 7.813 13.876 0 7.004-4.898 12.66-14.682 16.967 11.131 5.388 16.699 12.884 16.699 22.492 0 6.914-2.646 12.389-7.949 16.434-4.753 3.682-10.729 5.518-17.909 5.518-6.734 0-12.525-1.703-17.377-5.119-5.568-3.856-8.348-8.976-8.348-15.349 0-6.373 2.963-11.63 8.889-15.761 1.616-1.074 4.223-2.51 7.811-4.308zm4.572 1.912c-8.08 3.677-12.111 9.466-12.111 17.361 0 5.113 1.635 9.218 4.916 12.314 3.277 3.097 7.475 4.643 12.594 4.643 4.574 0 8.461-1.232 11.646-3.695 3.186-2.464 4.781-5.891 4.781-10.28 0-6.626-4.367-12.001-13.098-16.122l-8.728-4.221zm9.031-7.973c7.088-3.141 10.641-7.854 10.641-14.139 0-4.313-1.506-7.766-4.51-10.375-3.012-2.603-6.715-3.906-11.113-3.906-3.684 0-6.869.991-9.563 2.964-2.965 2.154-4.443 5.03-4.443 8.62 0 4.4 2.063 7.948 6.195 10.643.805.537 4.396 2.286 10.775 5.25l2.018.943z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
