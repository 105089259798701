<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g
            fill="none"
            fill-rule="evenodd"
        >
            <circle
                fill="#0D0F0F"
                cx="50"
                cy="50"
                r="50"
            />
            <path
                d="m78.945 24.039-12.648 7.344c6.16 9.471 9.238 18.224 9.238 26.25 0 6.582-1.969 11.858-5.895 15.83-3.938 3.978-9.219 5.963-15.871 5.963-11.113 0-23.719-5.74-37.824-17.22l10.094-9.877c9.656 8.673 18.73 13.005 27.246 13.005 3.078 0 5.207-.559 6.379-1.683 1.18-1.125 1.766-3.211 1.766-6.259 0-6.1-1.867-11.481-5.594-16.134L46.84 53.776V19.587l32.105 4.452Z"
                fill="#FFF"
            />
        </g>
    </svg>
</template>
