<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 100"
    >
        <g fill="none">
            <path
                id="Shape"
                d="M0 0v100c27.613 0 50-22.387 50-50.002 0-27.613-22.387-49.998-50-49.998z"
                fill="#F8F6D8"
            />
            <path
                d="M17.998 49.746c0-4.793-1.789-8.957-5.363-12.488-3.514-3.473-7.658-5.229-12.428-5.289v35.557c5.016-.055 9.225-1.729 12.617-5.037 3.449-3.36 5.174-7.612 5.174-12.743zm22.457-3.404c-2.859-4.207-5.428-6.311-7.697-6.311-1.85 0-3.197.883-4.037 2.652-.842 1.766-1.766 2.646-2.775 2.646-1.432 0-2.566-.508-3.406-1.516-.338-.334-1.01-1.428-2.02-3.279l4.291-3.027c1.514-1.012 2.271-2.104 2.271-3.277 0-.676-.213-1.727-.633-3.156-.422-1.43-.631-2.438-.631-3.027 0-1.262 1.684-2.398 5.049-3.406 3.699-1.012 6.475-1.766 8.326-2.271-8.498-1.514-14.09-2.27-16.781-2.27-4.289 0-6.604.59-6.939 1.766-.082.172-.021.93.191 2.273.209 1.344.314 2.143.314 2.395 0 2.523-1.223 3.785-3.658 3.785-1.18 0-2.902-.797-5.174-2.396 1.008-3.361 1.092-6.012.252-7.947-.252-.672-1.262-1.809-3.027-3.406-1.432-1.346-2.145-2.607-2.145-3.785 0-2.271 1.809-5.846 5.426-10.725-1.215.531-3.705 2.201-7.445 4.986v22.781c5.275.063 9.861 2.027 13.752 5.918 3.953 3.953 5.932 8.623 5.932 14.004.082 5.721-1.789 10.453-5.617 14.193-3.77 3.689-8.461 5.551-14.066 5.605v22.639c6.203-4.473 9.338-7.816 9.338-10.012 0-.42-.781-1.139-2.334-2.146-1.559-1.01-2.336-2.354-2.336-4.037 0-3.363 1.936-5.342 5.805-5.932 1.936 4.461 4.121 6.688 6.561 6.688.504 0 1.281-.254 2.334-.756 1.051-.506 1.744-.76 2.082-.76 2.438 0 4.75 4.293 6.938 12.871 1.684-7.484 2.523-13.037 2.523-16.652 0-3.785-.514-5.68-1.547-5.68-.344 0-1.033.129-2.064.379-1.033.252-1.848.379-2.449.379-.947 0-2.107-.629-3.482-1.893s-2.064-2.355-2.064-3.281c0-.672.801-2.225 2.398-4.668 2.859.674 4.752 1.01 5.678 1.01 2.271 0 3.783-1.025 4.541-3.092.76-2.059 1.811-3.092 3.154-3.092 1.85 0 6.057 1.852 12.617 5.551-3.368-4.793-5.85-8.368-7.446-10.721z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
