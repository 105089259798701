<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g
            transform="translate(0 -1)"
            fill="none"
        >
            <circle
                fill="#CAC5C0"
                cx="50"
                cy="50.998"
                r="50"
            />
            <path
                d="M34.309 19.322v50.838c0 6.047 2.516 9.07 7.537 9.07h1.279v3.447h-27.082v-3.447h1.789c5.109 0 7.668-3.023 7.668-9.07v-33.344c0-6.127-2.174-9.193-6.52-9.193h-2.682v-3.451h1.023c5.281 0 10.049-1.617 14.305-4.85h2.683zm20.058 27.459c4.684-4.422 9.408-6.643 14.18-6.643 5.109 0 9.236 2.049 12.391 6.135 2.895 3.746 4.34 8.305 4.34 13.668 0 5.791-1.703 10.859-5.109 15.199-3.746 4.855-8.43 7.281-14.049 7.281-6.643 0-12.006-2.895-16.098-8.684-3.576-5.109-5.365-11.156-5.365-18.141 0-7.49 2.043-14.371 6.135-20.631 4.084-6.258 9.58-10.707 16.477-13.348 3.83-1.451 7.922-2.174 12.262-2.174v3.322c-9.193.428-16.051 4.092-20.563 10.988-2.304 3.579-3.837 7.919-4.601 13.028zm-.64 6.264c-.082 1.273-.129 2.553-.129 3.83 0 4.938.852 9.496 2.559 13.672 2.213 5.445 5.445 8.17 9.707 8.17 3.66 0 6.43-1.871 8.301-5.619 1.363-2.895 2.043-6.43 2.043-10.604 0-4.34-.633-8.088-1.912-11.236-1.877-4.262-4.729-6.389-8.561-6.389-4.684 0-8.686 2.725-12.008 8.176z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
