<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 57 100"
    >
        <path
            d="M56.435 49.927c.06-5.453-1.672-10.957-5.068-15.233-1.64-1.806-2.991-3.864-4.728-5.572-3.832-3.801-9.566-4.054-14.18-6.449-.269-2.405-1.148-4.811-.616-7.239.162-.807.401-1.624-.03-2.396-1.211-2.788-.169-5.815-.385-8.721-.161-1.518-.059-3.377-1.506-4.315-.337 3.161-1.499 6.102-2.441 9.1-.435 2.338-.435 4.771-1.442 6.976.64 2.234-.626 4.113-1.995 5.761-3.029 1.551-6.629 1.919-9.263 4.193-1.878 1.595-3.906 2.992-6.007 4.289-1.577 1.973-3.322 3.874-4.026 6.367-2.818 3.402-3.151 7.97-4.749 11.956-.03 5.917 1.169 12.289 4.949 17.032 2.704 2.233 4.594 5.236 7.295 7.467 2.593 1.549 5.359 2.82 8.036 4.211 1.744.408 3.546.608 5.282 1.083 1.407 7.254 2.369 14.683 5.196 21.564.937-2.824.823-5.853 1.633-8.707 1.142-4.219-1.2-8.611.119-12.755 1.978-1.767 5.276-.619 7.469-2.137 4.176-2.574 8.594-5.082 11.623-9.02 1.128-2.264 3.496-3.956 3.496-6.672.019-3.656 2.152-7.083 1.337-10.782zm-30.993 22.38c-3.026-.736-5.844-2.183-8.381-3.95-2.731-1.819-3.749-5.213-6.443-7.076-2.523-3.009-1.978-7.122-3.011-10.689.414-2.489.903-4.956 1.162-7.476 1.23-1.995 3.129-3.586 3.655-5.989 1.805-2.585 4.55-4.372 6.754-6.612 1.604-1.85 4.157-1.068 6.264-1.142-.208 2.041-.373 4.153.137 6.164.249 1.238.807 2.457.616 3.751-.422 2.754.61 5.518-.119 8.241-1.472 5.69.134 11.438.273 17.176-.21 2.539-.33 5.1-.908 7.601zm22.936-10.786c-2.003 1.427-3.711 3.174-5.459 4.883-3.04 2.103-6.045 4.327-9.662 5.378.326-2.581.644-5.236-.178-7.762-2.019-5.708.453-11.679.871-17.451-.368-3.432-.017-7.093-1.592-10.254-.205-2.373.822-4.667 1.516-6.902 2.39.955 4.64 2.242 6.629 3.898 2.402 1.802 6.083 2.967 6.62 6.326.273 2.204 2.687 3.538 2.616 5.794-.149 5.363.591 10.971-1.362 16.092z"
            fill="#111212"
        />
    </svg>
</template>
