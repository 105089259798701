<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g
            transform="translate(0 -1)"
            fill="none"
        >
            <circle
                fill="#CAC5C0"
                cx="50"
                cy="50.998"
                r="50"
            />
            <path
                d="M52.307 7.25v27.246c0 3.26 1.371 4.889 4.117 4.889h.715v1.865h-14.496v-1.865h.91c2.656 0 3.98-1.629 3.98-4.889v-17.873c0-3.297-1.145-4.947-3.434-4.947h-1.457v-1.805h.523c2.832 0 5.395-.875 7.688-2.621h1.454zm9.246 74.254l-2.504 8.455h-20.82v-1.809c1.012-1.127 3.227-3.4 6.648-6.822 2.063-2.063 4.102-4.238 6.121-6.531 1.012-1.129 1.789-2.102 2.332-2.918 1.207-1.746 1.809-3.535 1.809-5.363 0-1.789-.555-3.363-1.66-4.723-1.105-1.363-2.531-2.045-4.285-2.045-3.773 0-6.727 2.551-8.863 7.643l-1.867-.701c2.527-7.15 6.508-10.729 11.953-10.729 2.684 0 4.965.912 6.855 2.738 1.887 1.828 2.828 4.082 2.828 6.768 0 3.422-1.965 6.766-5.891 10.029l-4.082 3.379c-2.605 2.184-4.766 4.225-6.473 6.129-.117.113-.352.391-.699.814h10.848c1.668 0 2.934-.33 3.789-.992.734-.582 1.457-1.689 2.156-3.32h1.805v-.002zm-40.821-31.049v-2.91h58.328v2.91h-58.328z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
