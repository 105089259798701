<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g
            transform="translate(0 -1)"
            fill="none"
        >
            <circle
                fill="#CAC5C0"
                cx="50"
                cy="50.998"
                r="50"
            />
            <path
                d="M36.58 45.675c5.934-5.577 11.91-8.364 17.939-8.364 6.564 0 11.828 2.606 15.787 7.821 3.682 4.77 5.529 10.521 5.529 17.27 0 7.376-2.158 13.849-6.475 19.425-4.768 6.114-10.703 9.174-17.807 9.174-8.547 0-15.381-3.69-20.504-11.063-4.59-6.477-6.883-14.167-6.883-23.071 0-9.44 2.607-18.141 7.824-26.101 5.213-7.958 12.184-13.602 20.906-16.928 4.947-1.888 10.168-2.839 15.65-2.839v4.184c-11.691.631-20.414 5.308-26.174 14.031-2.958 4.501-4.895 9.983-5.792 16.461zm-.812 8.092c-.086 1.618-.133 3.238-.133 4.857 0 6.203 1.078 11.96 3.236 17.269 2.785 6.922 6.883 10.385 12.279 10.385 4.674 0 8.18-2.381 10.52-7.151 1.797-3.684 2.697-8.135 2.697-13.355 0-5.571-.857-10.342-2.563-14.299-2.34-5.396-5.936-8.092-10.793-8.092-5.933-.001-11.017 3.464-15.243 10.386z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
