<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g
            fill="none"
            fill-rule="evenodd"
        >
            <circle
                fill="#CAC5C0"
                cx="50"
                cy="50"
                r="50"
            />
            <path
                d="M85.349 14.637C94.401 23.688 100 36.188 100 49.997 100 77.613 77.614 100 49.999 100c-13.806 0-26.305-5.596-35.354-14.646"
                fill="#C1D7E9"
            />
            <path
                d="M76.722 83.675c-2.692 2.739-6.008 4.108-9.947 4.108-4.422 0-7.929-1.514-10.524-4.54-2.452-2.884-3.676-6.558-3.676-11.028 0-4.804 2.09-10.281 6.271-16.434 3.41-5.047 7.423-9.467 12.037-13.264-.673 3.076-1.01 5.262-1.01 6.561 0 2.98.937 5.886 2.811 8.721 2.307 3.365 4.061 5.864 5.262 7.496 1.874 2.835 2.811 5.6 2.811 8.289.001 3.99-1.344 7.354-4.035 10.091Zm-.072-15.388c-.721-1.609-1.562-2.68-2.523-3.208.145.288.215.698.215 1.226 0 1.01-.287 2.453-.863 4.324l-.938 2.885c0 1.68.839 2.522 2.522 2.522 1.777 0 2.668-1.177 2.668-3.532 0-1.201-.36-2.608-1.081-4.217ZM43.646 41.593l-2.866 9.675H16.959V49.2c1.157-1.289 3.692-3.889 7.607-7.805a162.48 162.48 0 0 0 7.005-7.476c1.157-1.287 2.048-2.401 2.667-3.335 1.379-2 2.07-4.045 2.07-6.139 0-2.045-.633-3.846-1.901-5.402-1.267-1.559-2.903-2.338-4.905-2.338-4.313 0-7.694 2.915-10.14 8.742l-2.137-.801c2.891-8.185 7.448-12.276 13.678-12.276 3.069 0 5.682 1.044 7.839 3.135 2.159 2.091 3.236 4.67 3.236 7.74 0 3.915-2.245 7.739-6.738 11.478l-4.668 3.866c-2.981 2.492-5.453 4.827-7.406 7.007-.136.135-.402.446-.802.936h12.41c1.912 0 3.359-.377 4.335-1.135.845-.668 1.67-1.937 2.47-3.803l2.067-.001Z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
