<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g
            transform="translate(0 -1)"
            fill="none"
        >
            <circle
                fill="#CAC5C0"
                cx="50"
                cy="50.998"
                r="50"
            />
            <path
                d="M39.561 53.562l-2.018-3.771 1.337-.807c5.174-3.145 9.903-6.33 14.189-9.563 4.284-3.232 6.427-6.915 6.427-11.048 0-2.778-1.029-5.112-3.085-6.998-2.061-1.892-4.472-2.834-7.244-2.834-6.439 0-13.234 4.001-20.392 11.991l-3.354-2.559c7.453-11.316 16.566-16.974 27.341-16.974 4.757 0 8.795 1.168 12.122 3.503 3.771 2.607 5.655 6.153 5.655 10.642 0 3.229-1.349 6.374-4.039 9.426-1.528 1.798-4.226 4.132-8.083 7.004l-1.616 1.213c.718-.089 1.572-.134 2.56-.134 4.937 0 8.998 1.887 12.188 5.657 3.188 3.771 4.783 8.17 4.783 13.196 0 8.532-3.595 15.667-10.774 21.416-6.647 5.387-14.325 8.077-23.032 8.077-7.991 0-14.276-1.748-18.858-5.249l2.429-3.371c5.387 2.608 10.279 3.907 14.681 3.907 6.373 0 12.051-2.109 17.032-6.33 4.988-4.22 7.475-9.469 7.475-15.755 0-4.132-1.255-7.655-3.77-10.576-2.516-2.915-5.793-4.374-9.832-4.374-3.684.002-7.723 1.437-12.122 4.311z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
