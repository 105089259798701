<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g fill="none">
            <path
                id="Shape"
                d="M100 49.998c0 27.615-22.384 50.002-50 50.002-27.613 0-50-22.387-50-50.002 0-27.613 22.387-49.998 50-49.998 27.616 0 50 22.385 50 49.998z"
                fill="#C9C4BE"
            />
            <path
                d="M74.355 79.999h-53.76v-2.711l42.635-57.22h-18.979c-3.118.063-5.517.391-7.2.981-1.683.594-3.242 1.73-4.675 3.413-.125.126-.233.25-.327.374-.093.126-.203.25-.327.373-.25.375-.483.702-.702.982-.219.28-.42.577-.607.889-.063.188-.156.374-.28.561l-.374.562c-.126.374-.281.765-.467 1.168-.188.406-.407.889-.654 1.449l-3.367-.094v-14.491h51.049v2.524l-43.007 57.406h18.605c2.805.063 5.033 0 6.685-.188 1.65-.187 3.193-.59 4.628-1.213l.514-.188.421-.188c.873-.436 1.714-.935 2.524-1.496.809-.561 1.62-1.215 2.431-1.963.561-.562 1.122-1.168 1.683-1.822.561-.655 1.153-1.388 1.777-2.197l2.431-3.461h3.552l-4.209 16.55z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
