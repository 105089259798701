<script setup lang="ts">
import { computed } from "vue";
import MtgIcon from "@/Games/Mtg/icons/MtgIcon.vue";

type Props = {
    text: string;
};

const { text } = defineProps<Props>();

const segments = computed(() => {
    return text.split(/(\{.*?\})/g);
});

const isIcon = (segment: string) => {
    return segment.startsWith("{") && segment.endsWith("}");
};
</script>

<template>
    <div>
        <template
            v-for="(segment, index) in segments"
            :key="index"
        >
            <MtgIcon
                v-if="isIcon(segment)"
                :name="segment.slice(1, -1)"
            />
            <span
                v-else
                v-html="segment"
            />
        </template>
    </div>
</template>
