<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <g fill="none">
            <circle fill="#CAC5C0" cx="50" cy="50" r="50"/>
            <path
                d="M23 52.428c0-9.787 2.061-18.813 6.191-27.072 5.117-10.236 12.123-15.355 21.012-15.355 8.797 0 15.666 4.359 20.605 13.064 4.127 7.186 6.191 15.537 6.191 25.051 0 9.881-2.064 18.814-6.191 26.803-5.031 10.058-12.035 15.081-21.011 15.081-8.531 0-15.305-4.307-20.336-12.926-4.308-7.361-6.461-15.576-6.461-24.646zm11.309-4.444c0 12.926 1.93 22.984 5.795 30.168 2.691 5.025 6.146 7.541 10.367 7.541 10.146 0 15.221-10.506 15.221-31.518 0-9.244-.809-17.059-2.422-23.434-2.785-10.504-7.498-15.76-14.145-15.76-9.879 0-14.816 10.15-14.816 30.443v2.56z"
                fill="#0D0F0F"/>
        </g>
    </svg>
</template>
