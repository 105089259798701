<script setup lang="ts">
import {
    AntlerClasses,
    installAntlerComponent,
} from "../../../Utils/component";
import LorcanaInkAmber from "@/Games/Lorcana/icons/LorcanaInkAmber.vue";
import LorcanaInkAmethyst from "@/Games/Lorcana/icons/LorcanaInkAmethyst.vue";
import LorcanaInkEmerald from "@/Games/Lorcana/icons/LorcanaInkEmerald.vue";
import LorcanaInkRuby from "@/Games/Lorcana/icons/LorcanaInkRuby.vue";
import LorcanaInkSapphire from "@/Games/Lorcana/icons/LorcanaInkSapphire.vue";
import LorcanaInkSteel from "@/Games/Lorcana/icons/LorcanaInkSteel.vue";

export type IconName =
    | "Amethyst"
    | "Amber"
    | "Emerald"
    | "Ruby"
    | "Sapphire"
    | "Steel";
export type IconSize = "";

type Props = {
    name: IconName;
    size?: IconSize;
};

const { size = "regular", name } = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "inline-flex items-center justify-center h-7 w-7 basis-7 shrink-0",
};

const { aClass } = installAntlerComponent("lorcana-colors", { size }, classes);
</script>

<template>
    <LorcanaInkAmber
        v-if="name === 'Amber'"
        :class="aClass()"
    />
    <lorcana-ink-amethyst
        v-else-if="name === 'Amethyst'"
        :class="aClass()"
    />
    <LorcanaInkEmerald
        v-else-if="name === 'Emerald'"
        :class="aClass()"
    />
    <LorcanaInkRuby
        v-else-if="name === 'Ruby'"
        :class="aClass()"
    />
    <LorcanaInkSapphire
        v-else-if="name === 'Sapphire'"
        :class="aClass()"
    />
    <LorcanaInkSteel
        v-else-if="name === 'Steel'"
        :class="aClass()"
    />
</template>
