<script setup lang="ts">
type Props = {
    text: string;
};

const { text } = defineProps<Props>();

const replacements = {
    "{I}": "Ink",
    "{S}": "Strength",
    "{W}": "Willpower",
    "{L}": "Lore",
    "{E}": "Exert"
};

function replaceCharacters(str) {
    const keys = Object.keys(replacements).map(key => key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
    const regex = new RegExp(keys.join('|'), 'g');

    return str.replace(regex, match => replacements[match]);
}
</script>

<template>
    {{ replaceCharacters(text) }}
</template>
