<script setup lang="ts">
import {
    HoverCard,
    HoverCardTrigger,
    HoverCardContent,
} from "@/Components/Shared/hoverCard";
import AppLoader from "@/Components/Shared/loader/AppLoader.vue";
import { apiPathUrl } from "@/Utils/Helpers";
import { computed, ref } from "vue";
import AppImage from "@/Components/Shared/image/AppImage.vue";
import AppTitle from "@/Components/Shared/title/AppTitle.vue";
import MtgText from "@/Games/Mtg/MtgText.vue";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppButtonGroup from "@/Components/Shared/button/AppButtonGroup.vue";
import LorcanaText from "@/Games/Lorcana/LorcanaText.vue";
import LorcanaColors from "@/Games/Lorcana/icons/LorcanaColors.vue";
import LorcanaCost from "@/Games/Lorcana/icons/LorcanaCost.vue";
import AppText from "@/Components/Shared/text/AppText.vue";

type Props = {
    cardId: string;
};

const { cardId } = defineProps<Props>();

const loading = ref(false);
const card = ref(null);

const handleOpen = () => {
    if (card.value) {
        return;
    }

    loading.value = true;
    axios.get(apiPathUrl(`/cards/get-by-id/${cardId}`)).then(data => {
        card.value = data.data;
        loading.value = false;
    });
};

const setModel = computed(() => {
    if (!card.value) {
        return null;
    }
    return card.value.data.set && card.value.data.set.parent
        ? card.value.data.set.parent
        : card.value.data.set;
});
</script>

<template>
    <HoverCard
        :open-delay="500"
        @update:open="handleOpen"
    >
        <HoverCardTrigger as-child>
            <slot />
        </HoverCardTrigger>
        <HoverCardContent side="top" variant="card">
            <AppLoader v-if="loading" />
            <div
                v-if="card && card.data"
                class="flex flex-col gap-3"
            >
                <div class="flex flex-col sm:flex-row gap-6">
                    <AppLink :href="card.data.path">
                        <figure class="w-[200px] shrink-0">
                            <AppImage
                                :src="card.data.image"
                                :alt="card.data.name"
                                placeholder
                                variant="card"
                            />
                        </figure>
                    </AppLink>

                    <div class="flex flex-col gap-3">
                        <div class="flex flex-col gap-1">
                            <AppTitle
                                as="h3"
                                size="xsmall"
                            >
                                <AppLink :href="card.data.path">
                                    {{ card.data.name }}
                                </AppLink>
                            </AppTitle>

                            <div v-if="card.data.tcg_card.mana_cost">
                                <MtgText
                                    v-if="card.data.game.slug === 'mtg'"
                                    :text="card.data.tcg_card.mana_cost"
                                />
                            </div>

                            <AppText v-if="card.data.tcg_card.type" size="small" color="grayLight">
                                <template v-if="Array.isArray(card.data.tcg_card.type)">
                                    {{ card.data.tcg_card.type.join(", ") }}
                                </template>
                                <template v-else>
                                    {{ card.data.tcg_card.type }}
                                </template>
                            </AppText>

                            <div v-if="card.data.game.slug === 'lorcana'" class="flex gap-1 items-center">
                                <LorcanaColors
                                    v-if="card.data.tcg_card.ink"
                                    :name="card.data.tcg_card.ink"
                                />

                                <LorcanaCost
                                    v-if="card.data.tcg_card.cost && card.data.game.slug === 'lorcana'"
                                    :cost="card.data.tcg_card.cost"
                                    :inkable="card.data.tcg_card.inkwell"
                                />
                            </div>
                        </div>

                        <AppLink
                            v-if="setModel"
                            size="small"
                            :href="setModel.path"
                        >
                            {{ setModel.name }}
                        </AppLink>

                        <div
                            v-if="
                                card.data.tcg_card &&
                                card.data.tcg_card.oracle_text &&
                                card.data.game.slug === 'mtg'
                            "
                        >
                            <MtgText
                                :text="card.data.tcg_card.oracle_text"
                                class="text-sm"
                            />
                        </div>
                        <div
                            v-else-if="
                                card.data.tcg_card &&
                                card.data.tcg_card.text &&
                                card.data.game.slug === 'lorcana'
                            "
                            class="text-sm"
                        >
                            <LorcanaText :text="card.data.tcg_card.text" />
                        </div>
                    </div>
                </div>

                <AppButtonGroup>
                    <AppLink
                        :href="card.data.path"
                        variant="buttonSmall"
                    >
                        Bekijk kaart
                    </AppLink>
                </AppButtonGroup>
            </div>
        </HoverCardContent>
    </HoverCard>
</template>
