<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g fill="none">
            <path
                id="Shape"
                d="M100 50c0 27.609-22.382 50-50 50s-50-22.391-50-50 22.382-50 50-50 50 22.391 50 50z"
                fill="#CAC5C0"
            />
            <path
                d="M49.687 12.026c2.475 4.968 5.504 9.793 9.096 14.475 1.528 1.966 3.279 4.021 5.254 6.147 1.948 2.126 4.11 4.253 6.451 6.344 2.359 2.091 4.941 4.074 7.756 5.951 2.815 1.876 5.843 3.592 9.096 5.129-4.78 2.556-9.516 5.665-14.225 9.346-1.956 1.537-4.003 3.306-6.147 5.325-2.126 2.002-4.217 4.2-6.263 6.576-2.046 2.412-4.012 5.004-5.897 7.827-1.877 2.806-3.583 5.79-5.12 8.953-2.395-4.7-5.379-9.346-8.962-13.956-3.074-3.931-6.96-8.059-11.66-12.42-4.691-4.36-10.373-8.238-17.03-11.651 4.789-2.484 9.525-5.557 14.224-9.221 4.012-3.163 8.149-7.112 12.411-11.848 4.27-4.736 7.933-10.383 11.016-16.977zm-5.504 52.895c2.225 2.824 4.056 5.683 5.504 8.578 1.885-4.021 4.146-7.47 6.781-10.365 2.645-2.913 5.218-5.343 7.693-7.309 2.814-2.198 5.754-4.128 8.837-5.772-4.101-1.859-7.586-4.128-10.436-6.773-2.868-2.645-5.281-5.2-7.237-7.684-2.225-2.805-4.11-5.79-5.638-8.953-1.876 4.092-4.128 7.595-6.719 10.49-2.609 2.913-5.146 5.325-7.622 7.309-2.823 2.216-5.763 4.074-8.837 5.629 4.101 2.127 7.595 4.521 10.499 7.166 2.904 2.645 5.299 5.218 7.175 7.684z"
                fill="#000"
            />
        </g>
    </svg>
</template>
