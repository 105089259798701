<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 15"
    >
        <g
            fill="none"
            fill-rule="evenodd"
        >
            <path
                d="M20.553 0H7.447C3.334 0 0 3.358 0 7.5 0 11.642 3.334 15 7.447 15h13.106C24.666 15 28 11.642 28 7.5 28 3.358 24.666 0 20.553 0z"
                fill="#CAC5C0"
            />
            <path
                d="M6.49 1.95v8.895c0 1.065.444 1.596 1.335 1.596h.232v.609h-4.7v-.61h.295c.861 0 1.291-.53 1.291-1.595V5.01c0-1.077-.371-1.615-1.113-1.615h-.472v-.59h.17a3.99 3.99 0 0 0 2.49-.855h.472zm2.912 5.887c0-1.358.284-2.61.853-3.757.705-1.42 1.67-2.13 2.895-2.13 1.212 0 2.158.604 2.838 1.812.569.997.854 2.156.854 3.476 0 1.372-.285 2.61-.854 3.72-.693 1.395-1.658 2.092-2.894 2.092-1.176 0-2.109-.598-2.802-1.794-.594-1.021-.89-2.16-.89-3.42zm1.558-.617c0 1.794.266 3.19.798 4.186.37.697.847 1.046 1.428 1.046 1.398 0 2.097-1.457 2.097-4.372 0-1.283-.111-2.368-.333-3.252-.384-1.458-1.033-2.187-1.95-2.187-1.36 0-2.04 1.409-2.04 4.224v.355zm7.577.617c0-1.358.284-2.61.853-3.757.705-1.42 1.67-2.13 2.895-2.13 1.211 0 2.158.604 2.838 1.812.569.997.853 2.156.853 3.476 0 1.372-.284 2.61-.853 3.72-.693 1.395-1.658 2.092-2.895 2.092-1.175 0-2.108-.598-2.8-1.794-.595-1.021-.891-2.16-.891-3.42zm1.558-.617c0 1.794.265 3.19.798 4.186.37.697.847 1.046 1.428 1.046 1.398 0 2.097-1.457 2.097-4.372 0-1.283-.111-2.368-.334-3.252-.383-1.458-1.033-2.187-1.948-2.187-1.361 0-2.041 1.409-2.041 4.224v.355z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
