<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g
            transform="translate(0 -1)"
            fill="none"
        >
            <circle
                fill="#CAC5C0"
                cx="50"
                cy="50.998"
                r="50"
            />
            <path
                d="M53.244 64.266l-4.471 14.945h-36.791v-3.068c1.789-2.041 5.75-6.086 11.883-12.131 3.576-3.66 7.152-7.496 10.729-11.498 1.871-2.042 3.238-3.789 4.09-5.24 2.209-3.066 3.316-6.258 3.316-9.578 0-3.066-.998-5.813-2.998-8.238-2.002-2.428-4.539-3.639-7.6-3.639-6.643 0-11.84 4.512-15.588 13.541l-3.447-1.279c4.512-12.689 11.582-19.033 21.203-19.033 4.771 0 8.836 1.615 12.199 4.854 3.367 3.238 5.047 7.24 5.047 12.006 0 6.051-3.535 11.965-10.604 17.756l-7.152 6.004c-4.6 3.92-8.389 7.537-11.367 10.857-.256.172-.682.682-1.279 1.535h19.164c2.979 0 5.234-.642 6.768-1.918 1.279-1.021 2.557-2.98 3.83-5.875h3.068v-.001zm-.51-13.414c0-7.408 1.533-14.264 4.6-20.563 4.002-7.834 9.408-11.754 16.223-11.754 6.555 0 11.793 3.363 15.713 10.09 3.061 5.453 4.594 11.752 4.594 18.904 0 7.495-1.533 14.311-4.594 20.444-4.008 7.659-9.373 11.491-16.098 11.491-6.471 0-11.67-3.274-15.584-9.836-3.238-5.534-4.854-11.792-4.854-18.776zm8.686-3.448c0 6.389.68 12.096 2.043 17.116 2.041 7.84 5.451 11.754 10.219 11.754 7.748 0 11.627-8.047 11.627-24.146 0-19.928-4.217-29.891-12.646-29.891-7.496 0-11.242 7.748-11.242 23.248l-.001 1.919z"
                fill="#0D0F0F"
            />
        </g>
    </svg>
</template>
